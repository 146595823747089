"use client";

import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { ContentfulImage } from "../components/ContentfulImage";
import Logo from "../public/logo.svg";
import { formatDate } from "../utils/formatDate";
import { getRichTextRenderFunction } from "../utils/renderRichText";

import type { ContentNews, ContentTeamImage } from "./content";

const renderRichText = getRichTextRenderFunction(2);

type Props = {
  news: ContentNews[];
  teamImage: ContentTeamImage;
};

export function PageComponent(props: Props) {
  return (
    <>
      <Logo
        data-testid="logo"
        style={{
          maxWidth: "512px",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "16px",
        }}
      />
      <Flex flexDirection={["column", "column", "column", "row"]}>
        <Box width={["100%", "100%", "100%", "60%"]}>
          <Text>
            Willkommen auf der Homepage der Abteilung für alles rund um Ski und
            Board des TSV Eiselfing! Hier findet ihr alle Termine der aktuellen
            Saison, könnt euch einfach online anmelden und seit immer up-to-date
            über alles was bei uns so passiert.
          </Text>
          <Text mt={4}>
            Unser Team besteht aus zahlreichen ausgebildeten Skilehrerinnen und
            Skilehrern und aus noch mehr ehrenamtlichen Helfern. Wir arbeiten
            alle ehrenamtlich, voller Engagement und immer mit viel Spaß dafür,
            euch den Spaß am Skifahren und Snowboarden zu vermitteln.
          </Text>
          <Text mt={4}>
            Wenn ihr Lust habt euch aktiv einzubringen seid ihr bei uns
            jederzeit herzlich Willkommen!
          </Text>
          <Box borderRadius={8} overflow="hidden" mx="auto" mt={4}>
            <ContentfulImage
              src={props.teamImage.imageUrl}
              alt="Unser Team"
              height={500}
              width={750}
              style={{ width: "100%" }}
            />
          </Box>
        </Box>
        <Box
          mt={[8, 8, 8, 0]}
          ml={[0, 0, 0, 8]}
          width={["100%", "100%", "100%", "40%"]}
        >
          <Heading>Aktuelles aus dem Skiclub</Heading>
          {props.news.map((newsEntry) => (
            <Flex
              borderWidth="1px"
              rounded="lg"
              boxShadow="md"
              overflow="hidden"
              flexDirection={["column", "column", "row", "column"]}
              mt={4}
              key={newsEntry.id}
            >
              {newsEntry.imageUrl ? (
                <>
                  <Box
                    display={["none", "none", "block", "none"]}
                    width="30%"
                    position="relative"
                  >
                    <ContentfulImage
                      src={newsEntry.imageUrl}
                      alt="News"
                      height={200}
                      width={300}
                      style={{ height: "100%", objectFit: "cover" }}
                    />
                  </Box>
                  <Box display={["block", "block", "none", "block"]}>
                    <ContentfulImage
                      src={newsEntry.imageUrl}
                      alt="News"
                      height={200}
                      width={300}
                      style={{ width: "100%" }}
                    />
                  </Box>
                </>
              ) : null}
              <Box p={4}>
                <Heading size="lg">{newsEntry.title}</Heading>
                <Text fontSize="sm" color="gray.600" mt={1}>
                  {formatDate(newsEntry.date)}
                </Text>
                {renderRichText(newsEntry.text)}
              </Box>
            </Flex>
          ))}
          {props.news.length === 0 ? (
            <Text mt={4}>Im Moment gibt es nichts Neues.</Text>
          ) : null}
        </Box>
      </Flex>
    </>
  );
}
