import {
  Button,
  Flex,
  Heading,
  Link,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import RouterLink from "next/link";
import { cloneElement } from "react";
import { RichTextNode } from "../app/content/utils/transformText";

export const getRichTextRenderFunction = (
  spacing: number,
  { topMargin = true } = {},
) => {
  function renderRichTextNode(node: RichTextNode, index: number) {
    switch (node.nodeType) {
      case "heading-2":
        return (
          <Heading key={index} mt={spacing + 2}>
            {node.content.map(renderRichTextNode)}
          </Heading>
        );
      case "heading-3":
        return (
          <Heading key={index} as="h3" fontSize="lg" mt={spacing + 2}>
            {node.content.map(renderRichTextNode)}
          </Heading>
        );
      case "paragraph":
        return (
          <Text key={index} mt={spacing}>
            {node.content.map(renderRichTextNode)}
          </Text>
        );
      case "strong":
        return (
          <Text key={index} as="strong">
            {node.content.map(renderRichTextNode)}
          </Text>
        );
      case "text":
        return node.content;
      case "hyperlink":
        return node.isExternal ? (
          <Link key={index} href={node.href} color="blue.500" isExternal>
            {node.content.map(renderRichTextNode)}
          </Link>
        ) : (
          <Link key={index} as={RouterLink} href={node.href} color="blue.500">
            {node.content.map(renderRichTextNode)}
          </Link>
        );
      case "unordered-list":
        return (
          <List key={index} styleType="disc" listStylePosition="outside" pl={8}>
            {node.content.map(renderRichTextNode)}
          </List>
        );
      case "ordered-list":
        return (
          <List
            key={index}
            as="ol"
            styleType="decimal"
            listStylePosition="outside"
            pl={8}
          >
            {node.content.map(renderRichTextNode)}
          </List>
        );
      case "list-item":
        return (
          <ListItem key={index}>
            {node.content.map(renderRichTextNode)}
          </ListItem>
        );
      case "button":
        return (
          <Flex key={index} justifyContent="center" mt={spacing}>
            <Button as={RouterLink} href={node.href} colorScheme="blue">
              {node.content}
            </Button>
          </Flex>
        );
      default:
        return null;
    }
  }
  return function renderRichText(richText: RichTextNode[]) {
    return richText
      .map(renderRichTextNode)
      .map((element) =>
        element &&
        typeof element === "object" &&
        element.type.displayName === "List"
          ? cloneElement(element, { mt: spacing })
          : element,
      )
      .map((element, index) =>
        element && typeof element === "object" && index === 0 && !topMargin
          ? cloneElement(element, { mt: 0 })
          : element,
      );
  };
};
